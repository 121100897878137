<script lang="ts" setup>
import { useMediaQuery } from '@vueuse/core/index'
import { useStore } from '~/store/main'
import AkImage from '~/components/Ak/AkImage.vue'

const store = useStore()

const props = defineProps<{
  title?: string
  info?: string
  image: any
  text?: string
}>()

const consent = useCookie('consent')

const close = () => {
  store.$patch({
    isConsentOpen: false,
  })
  consent.value = 'true'

  const switchButton = document.querySelector('.account-switch__button')
  if (document.querySelector('.tuto-switch')) switchButton.click()
}

const isLaptop = useMediaQuery('(min-width: 1280px)')
</script>

<template>
  <div
    v-if="store.isConsentOpen"
    class="consent fixed bottom-0 left-0 right-0 top-0 z-[100] flex justify-center bg-black bg-opacity-70 lg:items-center"
    data-cy="consent-modal"
  >
    <div
      class="relative m-20 overflow-auto rounded-10 bg-white p-24 sm:max-w-[90vw] lg:max-w-[1100px] lg:p-48"
      data-cy="consent-modal-container"
    >
      <UIcon
        name="i-fa6-solid-xmark"
        class="absolute right-16 top-16 h-24 w-24 cursor-pointer"
        @click="close"
      />
      <div class="items-center lg:flex">
        <div class="flex-1">
          <h3
            v-if="title"
            class="text-primary mb-48 text-32 font-semibold leading-normal max-lg:pr-40"
            data-cy="consent-modal-title"
          >
            {{ title }}
          </h3>
          <div v-if="info" class="mb-24 flex items-center">
            <div
              class="border-primary flex h-24 w-24 items-center justify-center rounded-full border-2 bg-white font-bold"
            >
              !
            </div>
            <p class="text-primary ml-16 flex-1 text-p2 font-semibold">
              {{ info }}
            </p>
          </div>
          <AkText v-if="text" :text="text" />
          <UButton
            class="mt-32"
            block
            variant="outline"
            color="secondary"
            data-cy="consent-accept-button"
            @click="close"
            >{{ $t('consent.accept') }}</UButton
          >
        </div>
        <div
          v-if="image && image.data && isLaptop"
          class="min-h-full flex-1 max-lg:mt-20 max-lg:hidden lg:ml-48"
        >
          <AkImage :image="image" class="rounded-10" />
        </div>
      </div>
    </div>
  </div>
</template>
