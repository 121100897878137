import payload_plugin_qLmFnukI99 from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import strapi_CICqBBANNd from "/app/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formatedDate_ImOi5BRJWu from "/app/plugins/formatedDate.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_AOuQ1DYzjk,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  strapi_CICqBBANNd,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  formatedDate_ImOi5BRJWu,
  sentry_client_shVUlIjFLk
]