import _ from 'lodash'
import { setFields } from '~/utils/dto/shared'

export function getUserTitle(partnerUser: Object) {
  let userTitle = partnerUser.firstname + ' ' + partnerUser.lastname

  if ('persontype' in partnerUser) {
    userTitle = partnerUser.persontype.name + ' ' + userTitle
  }

  return userTitle
}

export function getPartnerUser(partnerUser: Object) {
  partnerUser._partner_child_id_authorized_array = []
  partnerUser.role = 'partner_user'

  if (_.has(partnerUser, 'fields')) {
    partnerUser = setFields(partnerUser)

    if (partnerUser._partner_child_id_authorized) {
      partnerUser._partner_child_id_authorized_array =
        partnerUser._partner_child_id_authorized
          .split(';')
          .filter((childId) => childId)
    }
  }

  return partnerUser
}

/**
 * Cette méthode permet de construire automatiquement l'ensemble des champs nécessaire pour faire l'update
 * En effet l'API investhub vide les champs s'ils sont absents du body, il faut alors toujours les envoyer
 * Il est important de maintenir ces champs côté auth0 (script Change Password + action Send Email Verification)
 *
 * @param partnerUser
 * @param body
 * @param fieldsToSet
 */
export function buildBody(
  partnerUser: Object,
  body: Object = {},
  fieldsToSet: Object = {}
): Object {
  const defaultBody = {
    firstname: partnerUser.firstname,
    lastname: partnerUser.lastname,
    email: partnerUser.email,
    telephone: partnerUser.telephone,
    confirmed: partnerUser.confirmed,
    rights: partnerUser.rights,
    funds: partnerUser.funds,
  }

  if ('persontype' in partnerUser) {
    defaultBody.persontype = partnerUser.persontype.id
  }

  let fields = []

  if ('fields' in partnerUser && partnerUser.fields) {
    fields = partnerUser.fields.map((field) => {
      let value = field.value

      if (field.name in fieldsToSet) {
        value = fieldsToSet[field.name]
      }

      return {
        id: field.id,
        value,
      }
    })
  }

  defaultBody.fields = fields

  return {
    ...defaultBody,
    ...body, // prioritaire
  }
}
