
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46url_93qBUnMzluknMeta } from "/app/pages/[...url].vue?macro=true";
import { default as historyIR4l0mlf3lMeta } from "/app/pages/account/history.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as securitySrTMI42t3JMeta } from "/app/pages/account/security.vue?macro=true";
import { default as teamV6NuqkxgJWMeta } from "/app/pages/account/team.vue?macro=true";
import { default as indexnFjuerN8cOMeta } from "/app/pages/articles/index.vue?macro=true";
import { default as showJgD1hI1UyBMeta } from "/app/pages/articles/show.vue?macro=true";
import { default as email_45not_45verified_45redirectwTbqgD1Vv3Meta } from "/app/pages/auth/investor/email-not-verified-redirect.vue?macro=true";
import { default as email_45not_45verifiedMmSahp8JOlMeta } from "/app/pages/auth/investor/email-not-verified.vue?macro=true";
import { default as create_45passwordqVQ3ZonFVQMeta } from "/app/pages/auth/investor/onboarding/create-password.vue?macro=true";
import { default as registerhTrCRfUwCOMeta } from "/app/pages/auth/investor/onboarding/register.vue?macro=true";
import { default as verifybqoiWDe6ycMeta } from "/app/pages/auth/investor/onboarding/verify.vue?macro=true";
import { default as welcome_45registerjn7gpHefckMeta } from "/app/pages/auth/investor/onboarding/welcome-register.vue?macro=true";
import { default as redirect_45to_45loginjek8xdxhC2Meta } from "/app/pages/auth/investor/redirect-to-login.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as email_45not_45verified_45redirectDF6mEiZzkjMeta } from "/app/pages/auth/partner/email-not-verified-redirect.vue?macro=true";
import { default as email_45not_45verifiedgxlU4GHVTEMeta } from "/app/pages/auth/partner/email-not-verified.vue?macro=true";
import { default as register3om6BaaWpvMeta } from "/app/pages/auth/partner/onboarding/register.vue?macro=true";
import { default as select_45partnerCtrD0ijCMeMeta } from "/app/pages/auth/partner/onboarding/select-partner.vue?macro=true";
import { default as redirect_45to_45login50NP5v9KQ6Meta } from "/app/pages/auth/partner/redirect-to-login.vue?macro=true";
import { default as redirect_45to_45loginbyHoriVc1PMeta } from "/app/pages/auth/redirect-to-login.vue?macro=true";
import { default as registerTCvAsPw3TEMeta } from "/app/pages/auth/register.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as comparebkoxSP3bIuMeta } from "/app/pages/funds/compare.vue?macro=true";
import { default as indexHESYCWIvjHMeta } from "/app/pages/funds/index.vue?macro=true";
import { default as showT9iAptR6iWMeta } from "/app/pages/funds/show.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as sitemapbFBPe0edUzMeta } from "/app/pages/sitemap.vue?macro=true";
import { default as showsik2EFeEOuMeta } from "/app/pages/whiteBook/show.vue?macro=true";
import { default as sitemapqjOM9u9BNrMeta } from "~/pages/sitemap.vue?macro=true";
import { default as contactYgHK3pEAL0Meta } from "~/pages/contact.vue?macro=true";
import { default as maintenancehfTrgCNiVdMeta } from "~/pages/maintenance.vue?macro=true";
import { default as indexvcG9H8G4iyMeta } from "~/pages/articles/index.vue?macro=true";
import { default as showAX2CX1J9UOMeta } from "~/pages/articles/show.vue?macro=true";
import { default as indexOreqd3chIaMeta } from "~/pages/funds/index.vue?macro=true";
import { default as comparevbtyjhU2JdMeta } from "~/pages/funds/compare.vue?macro=true";
import { default as showjfb7Zixg20Meta } from "~/pages/funds/show.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
import { default as indext7x5mN60ZyMeta } from "~/pages/account/index.vue?macro=true";
import { default as securityyWf6WBtd8JMeta } from "~/pages/account/security.vue?macro=true";
import { default as teamAk0T6vsBJ8Meta } from "~/pages/account/team.vue?macro=true";
import { default as historyRs1NUeLktbMeta } from "~/pages/account/history.vue?macro=true";
import { default as loginBdfQdmQhmTMeta } from "~/pages/auth/login.vue?macro=true";
import { default as email_45not_45verified_45redirectQJKxxHitCNMeta } from "~/pages/auth/partner/email-not-verified-redirect.vue?macro=true";
import { default as email_45not_45verified2UA4VJPiu3Meta } from "~/pages/auth/partner/email-not-verified.vue?macro=true";
import { default as email_45not_45verified_45redirectmpKPsuGnMDMeta } from "~/pages/auth/investor/email-not-verified-redirect.vue?macro=true";
import { default as email_45not_45verified9cX6vbT0ZrMeta } from "~/pages/auth/investor/email-not-verified.vue?macro=true";
import { default as register6OHaFttYHnMeta } from "~/pages/auth/register.vue?macro=true";
import { default as register5SFc1mDW8eMeta } from "~/pages/auth/investor/onboarding/register.vue?macro=true";
import { default as welcome_45registerpoDwGAYGgjMeta } from "~/pages/auth/investor/onboarding/welcome-register.vue?macro=true";
import { default as register9f5AP3weIeMeta } from "~/pages/auth/partner/onboarding/register.vue?macro=true";
import { default as select_45partnerATqsDYTiYoMeta } from "~/pages/auth/partner/onboarding/select-partner.vue?macro=true";
import { default as create_45passwordnRQs8jQqDhMeta } from "~/pages/auth/investor/onboarding/create-password.vue?macro=true";
import { default as verifyd8oyZdKaLoMeta } from "~/pages/auth/investor/onboarding/verify.vue?macro=true";
import { default as showSGQLZmXzeyMeta } from "~/pages/whiteBook/show.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "url",
    path: "/:url(.*)*",
    meta: _91_46_46_46url_93qBUnMzluknMeta || {},
    component: () => import("/app/pages/[...url].vue")
  },
  {
    name: "account-history",
    path: "/account/history",
    component: () => import("/app/pages/account/history.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    component: () => import("/app/pages/account/security.vue")
  },
  {
    name: "account-team",
    path: "/account/team",
    component: () => import("/app/pages/account/team.vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexnFjuerN8cOMeta || {},
    component: () => import("/app/pages/articles/index.vue")
  },
  {
    name: "articles-show",
    path: "/articles/show",
    meta: showJgD1hI1UyBMeta || {},
    component: () => import("/app/pages/articles/show.vue")
  },
  {
    name: "auth-investor-email-not-verified-redirect",
    path: "/auth/investor/email-not-verified-redirect",
    meta: email_45not_45verified_45redirectwTbqgD1Vv3Meta || {},
    component: () => import("/app/pages/auth/investor/email-not-verified-redirect.vue")
  },
  {
    name: "auth-investor-email-not-verified",
    path: "/auth/investor/email-not-verified",
    meta: email_45not_45verifiedMmSahp8JOlMeta || {},
    component: () => import("/app/pages/auth/investor/email-not-verified.vue")
  },
  {
    name: "auth-investor-onboarding-create-password",
    path: "/auth/investor/onboarding/create-password",
    meta: create_45passwordqVQ3ZonFVQMeta || {},
    component: () => import("/app/pages/auth/investor/onboarding/create-password.vue")
  },
  {
    name: "auth-investor-onboarding-register",
    path: "/auth/investor/onboarding/register",
    meta: registerhTrCRfUwCOMeta || {},
    component: () => import("/app/pages/auth/investor/onboarding/register.vue")
  },
  {
    name: "auth-investor-onboarding-verify",
    path: "/auth/investor/onboarding/verify",
    meta: verifybqoiWDe6ycMeta || {},
    component: () => import("/app/pages/auth/investor/onboarding/verify.vue")
  },
  {
    name: "auth-investor-onboarding-welcome-register",
    path: "/auth/investor/onboarding/welcome-register",
    meta: welcome_45registerjn7gpHefckMeta || {},
    component: () => import("/app/pages/auth/investor/onboarding/welcome-register.vue")
  },
  {
    name: "auth-investor-redirect-to-login",
    path: "/auth/investor/redirect-to-login",
    meta: redirect_45to_45loginjek8xdxhC2Meta || {},
    component: () => import("/app/pages/auth/investor/redirect-to-login.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "auth-partner-email-not-verified-redirect",
    path: "/auth/partner/email-not-verified-redirect",
    meta: email_45not_45verified_45redirectDF6mEiZzkjMeta || {},
    component: () => import("/app/pages/auth/partner/email-not-verified-redirect.vue")
  },
  {
    name: "auth-partner-email-not-verified",
    path: "/auth/partner/email-not-verified",
    meta: email_45not_45verifiedgxlU4GHVTEMeta || {},
    component: () => import("/app/pages/auth/partner/email-not-verified.vue")
  },
  {
    name: "auth-partner-onboarding-register",
    path: "/auth/partner/onboarding/register",
    meta: register3om6BaaWpvMeta || {},
    component: () => import("/app/pages/auth/partner/onboarding/register.vue")
  },
  {
    name: "auth-partner-onboarding-select-partner",
    path: "/auth/partner/onboarding/select-partner",
    meta: select_45partnerCtrD0ijCMeMeta || {},
    component: () => import("/app/pages/auth/partner/onboarding/select-partner.vue")
  },
  {
    name: "auth-partner-redirect-to-login",
    path: "/auth/partner/redirect-to-login",
    meta: redirect_45to_45login50NP5v9KQ6Meta || {},
    component: () => import("/app/pages/auth/partner/redirect-to-login.vue")
  },
  {
    name: "auth-redirect-to-login",
    path: "/auth/redirect-to-login",
    meta: redirect_45to_45loginbyHoriVc1PMeta || {},
    component: () => import("/app/pages/auth/redirect-to-login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerTCvAsPw3TEMeta || {},
    component: () => import("/app/pages/auth/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactV6oPJjs9RyMeta || {},
    component: () => import("/app/pages/contact.vue")
  },
  {
    name: "funds-compare",
    path: "/funds/compare",
    meta: comparebkoxSP3bIuMeta || {},
    component: () => import("/app/pages/funds/compare.vue")
  },
  {
    name: "funds",
    path: "/funds",
    meta: indexHESYCWIvjHMeta || {},
    component: () => import("/app/pages/funds/index.vue")
  },
  {
    name: "funds-show",
    path: "/funds/show",
    meta: showT9iAptR6iWMeta || {},
    component: () => import("/app/pages/funds/show.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    component: () => import("/app/pages/maintenance.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    meta: sitemapbFBPe0edUzMeta || {},
    component: () => import("/app/pages/sitemap.vue")
  },
  {
    name: "whiteBook-show",
    path: "/whiteBook/show",
    meta: showsik2EFeEOuMeta || {},
    component: () => import("/app/pages/whiteBook/show.vue")
  },
  {
    name: "sitemap",
    path: "/plan-du-site",
    meta: sitemapqjOM9u9BNrMeta || {},
    component: () => import("~/pages/sitemap.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactYgHK3pEAL0Meta || {},
    component: () => import("~/pages/contact.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancehfTrgCNiVdMeta || {},
    component: () => import("~/pages/maintenance.vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexvcG9H8G4iyMeta || {},
    component: () => import("~/pages/articles/index.vue")
  },
  {
    name: "article",
    path: "/articles/:id",
    meta: showAX2CX1J9UOMeta || {},
    component: () => import("~/pages/articles/show.vue")
  },
  {
    name: "fonds",
    path: "/fonds",
    meta: indexOreqd3chIaMeta || {},
    component: () => import("~/pages/funds/index.vue")
  },
  {
    name: "funds",
    path: "/funds",
    component: () => import("~/pages/funds/index.vue")
  },
  {
    name: "fundsCompare",
    path: "/fonds/comparer",
    meta: comparevbtyjhU2JdMeta || {},
    component: () => import("~/pages/funds/compare.vue")
  },
  {
    name: "fond",
    path: "/fonds/:id",
    meta: showjfb7Zixg20Meta || {},
    component: () => import("~/pages/funds/show.vue")
  },
  {
    name: "fund",
    path: "/funds/:id",
    component: () => import("~/pages/funds/show.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexp9V2p5fxycMeta || {},
    component: () => import("~/pages/index.vue")
  },
  {
    name: "accountProfil",
    path: "/mon-compte",
    component: () => import("~/pages/account/index.vue")
  },
  {
    name: "accountSecurity",
    path: "/mon-compte/securite",
    component: () => import("~/pages/account/security.vue")
  },
  {
    name: "accountTeam",
    path: "/mon-compte/equipe",
    component: () => import("~/pages/account/team.vue")
  },
  {
    name: "accountHistory",
    path: "/mon-compte/historique",
    component: () => import("~/pages/account/history.vue")
  },
  {
    name: "frLogin",
    path: "/connexion",
    meta: loginBdfQdmQhmTMeta || {},
    component: () => import("~/pages/auth/login.vue")
  },
  {
    name: "partnerEmailNotVerifiedRedirect",
    path: "/partner/email-not-verified-redirect",
    meta: email_45not_45verified_45redirectQJKxxHitCNMeta || {},
    component: () => import("~/pages/auth/partner/email-not-verified-redirect.vue")
  },
  {
    name: "partnerEmailNotVerified",
    path: "/partner/email-not-verified",
    meta: email_45not_45verified2UA4VJPiu3Meta || {},
    component: () => import("~/pages/auth/partner/email-not-verified.vue")
  },
  {
    name: "investorEmailNotVerifiedRedirect",
    path: "/investor/email-not-verified-redirect",
    meta: email_45not_45verified_45redirectmpKPsuGnMDMeta || {},
    component: () => import("~/pages/auth/investor/email-not-verified-redirect.vue")
  },
  {
    name: "investorEmailNotVerified",
    path: "/investor/email-not-verified",
    meta: email_45not_45verified9cX6vbT0ZrMeta || {},
    component: () => import("~/pages/auth/investor/email-not-verified.vue")
  },
  {
    name: "frRegister",
    path: "/inscription",
    meta: register6OHaFttYHnMeta || {},
    component: () => import("~/pages/auth/register.vue")
  },
  {
    name: "frInvestorOnboardingRegister",
    path: "/investisseur/inscription",
    meta: register5SFc1mDW8eMeta || {},
    component: () => import("~/pages/auth/investor/onboarding/register.vue")
  },
  {
    name: "frInvestorOnboardingWelcomeRegister",
    path: "/bienvenue",
    meta: welcome_45registerpoDwGAYGgjMeta || {},
    component: () => import("~/pages/auth/investor/onboarding/welcome-register.vue")
  },
  {
    name: "frPartnerOnboardingRegister",
    path: "/partenaire/inscription",
    meta: register9f5AP3weIeMeta || {},
    component: () => import("~/pages/auth/partner/onboarding/register.vue")
  },
  {
    name: "frPartnerOnboardingSelectPartner",
    path: "/partenaire/choisir-son-equipe",
    meta: select_45partnerATqsDYTiYoMeta || {},
    component: () => import("~/pages/auth/partner/onboarding/select-partner.vue")
  },
  {
    name: "frInvestorOnboardingCreatePassword",
    path: "/investisseur/creer-son-mot-de-passe",
    meta: create_45passwordnRQs8jQqDhMeta || {},
    component: () => import("~/pages/auth/investor/onboarding/create-password.vue")
  },
  {
    name: "frInvestorOnboardingVerify",
    path: "/investisseur/verification",
    meta: verifyd8oyZdKaLoMeta || {},
    component: () => import("~/pages/auth/investor/onboarding/verify.vue")
  },
  {
    name: "frWhiteBook",
    path: "/livre-blanc/:slug",
    meta: showSGQLZmXzeyMeta || {},
    component: () => import("~/pages/whiteBook/show.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/investor/send-reset-password",
    component: component_45stubnO9MU04yTU
  }
]