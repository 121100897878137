import _ from 'lodash'
import { getLogoutUrl } from '~/utils/auth0/user/methods'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { data: auth, signOut } = useAuth()

  if (!_.has(auth.value, 'error')) {
    return
  }

  /*
   * Déconnecte l'utilisateur s'il est introuvable
   * Utile lorsqu'il est supprimé (investhub ou auth0)
   */
  if (auth.value.error === 'user_not_found') {
    await signOut({ callbackUrl: getLogoutUrl() })
  }
})
