<script setup lang="ts">
import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import formbricks from '@formbricks/js'
import type { strapiNavigationItem } from '~/interfaces/strapi.interface'
import { useStore } from '~/store/main'
import type {Strapi4ResponseMany, Strapi4ResponseSingle} from "@nuxtjs/strapi/dist/runtime/types/v4";

const { find, findOne } = useStrapi()
const { locale } = useI18n()
const store = useStore()
const { data: user } = useAuth()
const gtm = useGtm()
const route = useRoute()
const router = useRouter()
const config = useRuntimeConfig()

onMounted(async () => {
  window.sessionStorage.setItem('locale', locale.value)

  if (config.public.cookiebot) {
    const script = document.createElement('script')
    script.setAttribute('data-cbid', config.public.cookiebot)
    script.setAttribute('id', 'Cookiebot')
    script.setAttribute('data-blockingmode', 'auto')
    script.type = 'text/javascript'
    script.src = 'https://consent.cookiebot.com/uc.js'
    document.head.appendChild(script)
  }

  if (config.public.survey.env && config.public.survey.url && user.value) {
    formbricks.init({
      environmentId: config.public.survey.env,
      apiHost: config.public.survey.url,
      userId: user.value.user.id,
      attributes: {
        email: user.value.user.email,
        firstname: user.value.user.firstname,
        lastname: user.value.user.lastname,
      },
    })

    formbricks.track('open')
  }
})

const topMenu = ref()
const headerMenu = ref()
const footerMenu = ref()
const footerMenuSeo = ref()

// const topNavigation =
//   locale.value === 'fr'
//     ? 'navigation/render/top-navigation?type=TREE'
//     : 'navigation/render/top-navigation-en?type=TREE'
//
// const { data: dataTop } = await useAsyncData('topNavigation', () =>
//   find<strapiNavigationItem[]>(topNavigation)
// )

// if (dataTop.value) topMenu.value = dataTop.value
topMenu.value = []

let headerNavigation: string

const { status } = useAuth()
if (locale.value === 'fr') {
  if (status.value === 'authenticated') {
    headerNavigation = 'navigation/render/header-navigation-connected?type=TREE'
  } else {
    headerNavigation = 'navigation/render/header-navigation?type=TREE'
  }
} else if (status.value === 'authenticated') {
  headerNavigation =
    'navigation/render/header-navigation-connected-en?type=TREE'
} else {
  headerNavigation = 'navigation/render/header-navigation-en?type=TREE'
}

const { data: dataHeader } = await useCachedAsyncData<Strapi4ResponseMany>(
  `headerNavigation_${locale.value}_${status.value}`,
  () => find<strapiNavigationItem[]>(headerNavigation),
  {
    serverMaxAge: config.public.serverMaxAge,
  }
)

if (dataHeader.value) headerMenu.value = dataHeader.value
else headerMenu.value = []

const footerNavigation =
  locale.value === 'fr'
    ? 'navigation/render/footer-navigation?type=TREE'
    : 'navigation/render/footer-navigation-en?type=TREE'

const { data: dataFooter } = await useCachedAsyncData<Strapi4ResponseMany>(
  `footerNavigation_${locale.value}`,
  () => find<strapiNavigationItem[]>(footerNavigation),
  {
    serverMaxAge: config.public.serverMaxAge,
  }
)

if (dataFooter.value) footerMenu.value = dataFooter.value
else footerMenu.value = []

const footerSeoNavigation =
  locale.value === 'fr'
    ? 'navigation/render/footer-seo?type=TREE'
    : 'navigation/render/footer-seo-en?type=TREE'

const { data: dataFooterSeo } = await useCachedAsyncData<Strapi4ResponseMany>(
  `footerSeoNavigation_${locale.value}`,
  () => find<strapiNavigationItem[]>(footerSeoNavigation),
  {
    serverMaxAge: config.public.serverMaxAge,
  }
)

if (dataFooterSeo.value) footerMenuSeo.value = dataFooterSeo.value
else footerMenuSeo.value = []

// options

const { data: layout } = await useCachedAsyncData<Strapi4ResponseMany>(
  `layout_${locale.value}`,
  () =>
    findOne('header-footer', {
      populate: 'url_match, socials, socials.icon, logo_header, logo_footer',
      locale: locale.value as StrapiLocale,
    }),
  {
    serverMaxAge: config.public.serverMaxAge,
  }
)

const { data: loginRegister } = await useCachedAsyncData<Strapi4ResponseMany>(
  `loginRegister_${locale.value}`,
  () =>
    findOne('login-register', {
      populate: 'login_background, logo_blue, image_register',
      locale: locale.value as StrapiLocale,
    }),
  {
    serverMaxAge: config.public.serverMaxAge,
  }
)

const { data: welcomeConsent } = await useCachedAsyncData<Strapi4ResponseMany>(
  `welcome_${locale.value}`,
  () =>
    findOne('welcome', {
      populate: 'consent_image,',
      locale: locale.value as StrapiLocale,
    }),
  {
    serverMaxAge: config.public.serverMaxAge
  }
)

onBeforeMount(() => {
  if (
    welcomeConsent.value &&
    welcomeConsent.value.data &&
    welcomeConsent.value.data.attributes.consent_image.data
  ) {
    const link = document.createElement('link')
    link.setAttribute('rel', 'preload')
    link.setAttribute('as', 'image')
    link.setAttribute(
      'href',
      welcomeConsent.value.data.attributes.consent_image.data.attributes.url
    )
    document.head.appendChild(link)
  }
})

watch(
  () => route.fullPath,
  () => {
    store.$patch({
      isMenuAccountOpen: false,
    })
  }
)

const isLoginPage = computed(() => {
  return route.meta.customLayout && route.meta.customLayout === 'login'
})

const closeLogin = () => {
  gtm.trackEvent({
    event: 'login',
    action: 'close',
    value: 'Click on the cross in login page',
  })
  router.push('/')
}

const layoutClass = computed(() => {
  if (route.meta.customLayout && route.meta.customLayout === 'empty')
    return 'empty'
  if (isLoginPage.value) return 'layout-login'
  return 'layout'
})

provide('layout', layout)
provide('loginRegister', loginRegister)
provide('welcomeConsent', welcomeConsent)
</script>

<template>
  <AkConsent
    :title="welcomeConsent?.data.attributes.consent_title"
    :info="welcomeConsent?.data.attributes.consent_info"
    :image="welcomeConsent?.data.attributes.consent_image"
    :text="welcomeConsent?.data.attributes.consent_text"
  />
  <div :class="layoutClass">
    <button
      v-if="isLoginPage && route.name !== 'frPartnerOnboardingSelectPartner'"
      class="text-primary absolute right-24 top-24 z-40 block"
      @click="closeLogin"
    >
      <UIcon name="i-fa6-solid-xmark" class="h-24 w-24 lg:text-white" />
    </button>
    <AkHeader
      v-if="!isLoginPage && route.meta.customLayout !== 'empty'"
      :menu="[...headerMenu]"
      :top="[...topMenu]"
    />
    <slot />
    <TutoSwitch
      v-if="!isLoginPage && user && user.user.role === 'partner_user'"
    />
    <AkFooter
      v-if="route.meta.customLayout !== 'empty'"
      :class="{ 'mt-80': !isLoginPage }"
      :menu="[...footerMenu]"
      :menu-seo="[...footerMenuSeo]"
    />
    <UNotifications />
    <NuxtLoadingIndicator color="#269999" />
  </div>
</template>

<style lang="scss">
body {
  @apply font-opensans text-[1rem] leading-normal text-black;
}

span[class^='i-fa6-'] {
  mask-size: contain !important;
  mask-position: center !important;
}

select option {
  @apply text-black;
}

.carousel-indicator {
  @apply bottom-0 h-10 w-10 mix-blend-normal;
}

.layout-login {
  @media (min-width: 1280px) {
    min-height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr;
  }
}

.layout {
  @media (min-width: 1280px) {
    min-height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr;
  }
}

.aspect-video {
  aspect-ratio: 16/9;
}

.text-h1 {
  @apply font-montserrat text-42 leading-normal lg:text-64 lg:leading-none;
}

.text-h2 {
  @apply font-montserrat text-34 leading-normal lg:text-62 lg:leading-none;
}

.text-h3 {
  @apply font-montserrat text-30 font-semibold leading-normal lg:text-48;
}

.text-h4 {
  @apply font-montserrat text-24 font-semibold leading-none tracking-[-0.48px] lg:leading-normal;
}

.text-h5 {
  @apply font-montserrat text-20 font-semibold leading-32 lg:font-normal;
}

.text-h6 {
  @apply font-montserrat text-18 font-semibold leading-normal;
}

.text-basic {
  @apply text-14 font-bold leading-normal;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

#CookiebotWidget {
  display: none;
}

.custom-checkbox {
  label {
    margin-top: 2px;
  }
}

.dropdown {
  .dropdown__chevron {
    @apply transition;
  }

  &[data-headlessui-state='open'] .dropdown__chevron {
    transform: rotate(180deg);
  }
}
</style>
